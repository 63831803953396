exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-staffing-js": () => import("./../../../src/pages/agency-staffing.js" /* webpackChunkName: "component---src-pages-agency-staffing-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logistics-js": () => import("./../../../src/pages/logistics.js" /* webpackChunkName: "component---src-pages-logistics-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */)
}

